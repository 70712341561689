let openmenu = document.querySelector('.openmenu');
let closemenu = document.querySelector('.closemenu');
let menuHand = document.getElementById('menuhand');
let menu = document.getElementById('menu')

export let menuAnim = openmenu.addEventListener('click', () => {

        function showMenuHand() {
            menuHand.style.display = 'flex';
        }
        setTimeout(showMenuHand, 0);

        function hideMenuHand() {
            menuHand.style.display = 'none';
        }
        setTimeout(hideMenuHand, 2000);

        function openMenu() {
            menu.style.transform = 'translateY(0vh)';
        }
        setTimeout(openMenu, 970);

        function showOpacityMenu() {
            menu.style.opacity = '1';
        }
        setTimeout(showOpacityMenu, 0);
    });

export let closeMenu = closemenu.addEventListener('click', () => {

            function closeMenu() {
                    menu.style.transform = 'translateY(-100vh)';
                }
                setTimeout(closeMenu, 0);

            function hideOpacityMenu() {
                    menu.style.opacity = '0';
                }
                setTimeout(hideOpacityMenu, 700);
        
            });

