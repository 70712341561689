export let cursor = document.addEventListener('mousemove', e => {
    document.querySelector('.cursor').setAttribute("style", "top: "+(e.pageY)+"px; left: "+(e.pageX)+"px;")
});

/* const cursorElement = document.querySelector('.cursor'); // Stocke l'élément

let mouseX = 0, mouseY = 0;
let isMoving = false;

document.addEventListener('mousemove', (e) => {
    mouseX = e.clientX; // Utilise clientX/clientY pour un meilleur tracking
    mouseY = e.clientY;

    if (!isMoving) {
        isMoving = true;
        requestAnimationFrame(updateCursor);
    }
});

function updateCursor() {
    cursorElement.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
    isMoving = false;
} */
