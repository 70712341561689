
let loader = document.querySelector('.loader');

export let showAfterLoader = () => {
     
        loader.classList.add('hidden');
 }
        setTimeout(showAfterLoader,3000);
 

 // Hide sunglasses reflect
document.querySelectorAll('.reflect').forEach((item) => {

    let showReflect =  () => {
            item.style.display = "block";
        }
        setTimeout(showReflect,3800);

        let hideReflect =  () => {
            item.style.display = "none";
        }
        setTimeout(hideReflect,5100);
});

// Open award box
    document.querySelector('.awards-right').addEventListener('click', () => {
        document.querySelector('.awards-container').style.display = "flex";
        document.querySelector('.awards-home').classList.add('popup');
    })

    document.querySelector('.close-award').addEventListener('click', () => {
        document.querySelector('.awards-container').style.display = "none";
        document.querySelector('.awards-home').classList.remove('popup');
    })

// Show Hey Welcome!
let clickMe = document.getElementById('clickme');
let welcomeAnim = document.querySelector('.wlcome');
let hey = document.querySelector('.hey');

export let heyWelcomAnim = clickMe.addEventListener('click', () => {
        document.getElementById('heywelcome').classList.add('visible');

        function showAnimHey() {
            hey.classList.add('heyanim');
        }
        setTimeout(showAnimHey, 0);

        function hideHey() {
            hey.classList.add('hidden');
        }
        setTimeout(hideHey, 2000);

        function showWelcome() {
            welcomeAnim.classList.add('visible');
        }
        setTimeout(showWelcome, 2100);

        function showAnimWelcome() {
            welcomeAnim.classList.add('heyanim');
        }
        setTimeout(showAnimWelcome, 2100);

        function hideWelcome() {
            welcomeAnim.classList.remove('visible');
        }
        setTimeout(hideWelcome, 5000);

        function hideButton() {
            document.getElementById('clickme' && 'homebutton').classList.add('hidden');
        }
        setTimeout(hideButton, 5000);

        function showArrow() {
            document.getElementById('mouse-box').style.visibility = 'visible';
        }
        setTimeout(showArrow, 5300);

        function hideArrow() {
            document.getElementById('mouse-box').style.visibility = 'hidden';
        }
        setTimeout(hideArrow, 12000);
    });

// Show / hide Work bubble
    let showOrHideWorkBubble = document.querySelector('.mywork');
    let bubbleBox = document.getElementById('workbubble-box');

    export let overWorkBubble =  showOrHideWorkBubble.addEventListener('mouseover', (e) => {
            bubbleBox.classList.add('visible');
        });

    export let leaveWorkBubble = showOrHideWorkBubble.addEventListener('mouseleave', (e) => {

            function hideAnim() {
                bubbleBox.classList.add('hide-bubble');
            }
            setTimeout(hideAnim, 0);

            function HideAnimOff() {
                bubbleBox.classList.remove('hide-bubble');
            }
            setTimeout(HideAnimOff, 400);

            function hideBubble() {
                bubbleBox.classList.remove('visible');
            }
            setTimeout(hideBubble, 400);
        });


// Show / hide links bubble
    let linkbubbleBox = document.getElementById('linkbubble-box');
    let bubbleLink = document.querySelector('.links-txt');

        bubbleLink.addEventListener('mouseover', () => {
                linkbubbleBox.classList.add('visible');
            });

        bubbleLink.addEventListener('mouseleave', () => {

                function hideLinkAnim() {
                    linkbubbleBox.classList.add('hide-bubble');
                }
                setTimeout(hideLinkAnim, 0);

                function HideLinkAnimOff() {
                    linkbubbleBox.classList.remove('hide-bubble');
                }
                setTimeout(HideLinkAnimOff, 400);

                function hideLinkBubble() {
                    linkbubbleBox.classList.remove('visible');
                }
                setTimeout(hideLinkBubble, 400);
            });

        bubbleLink.addEventListener('click', () => {
                linkbubbleBox.classList.add('shake');
            });

        bubbleLink.addEventListener('mouseleave', () => {
                linkbubbleBox.classList.remove('shake');
            });

            
    var scrollContainer = document.querySelector('.container');
    var box1 = document.getElementById('box1');
    var box2  = document.getElementById('box2');
    var box3 = document.getElementById('box3');
    var box4 = document.getElementById('box4');
            
        let arrow = document.querySelector('.arrows__right');
            box2.addEventListener('mouseover', () => {
                function showArrows () {
                    arrow.style.display = 'block';
                    }
                    setTimeout (showArrows,300)
            }); 
            box4.addEventListener('mouseleave', () => {
                    arrow.style.visibility = 'hidden';
            }); 
        
    export const mobileMedia = window.matchMedia("(max-width: 1024px)");

    // http://www.gizma.com/easing/
    export function ease(t,b,c,d){
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    }
   
// Functions test for slides anim (Duplicate code : refactoring needed)
  export {scrollContainer, box1, box2, box3, box4};

    function ScrollOne(target, duration){
        var target = document.querySelector(target);
        if(mobileMedia.matches){
            var startPosition = box1.offsetTop;
            var targetPosition = target.getBoundingClientRect().top;    
            }else{
            var startPosition = box1.offsetLeft;
            var targetPosition = target.getBoundingClientRect().left; 
            }

           var startTime = null;
    
      function animation(currentTime){
        if(startTime === null) startTime = currentTime;
        var timeElapsed = currentTime - startTime;
        var run = ease(timeElapsed, startPosition, targetPosition, duration);
    
        scrollContainer.scrollTo(0,run);
    
        if(timeElapsed < duration) requestAnimationFrame(animation);
    }
 
	ease();
	requestAnimationFrame(animation);
};  

    function ScrollTwo(target, duration){
        var target = document.querySelector(target);
        if(mobileMedia.matches){
            var startPosition = box2.offsetTop;
            var targetPosition = target.getBoundingClientRect().top;    
            }else{
            var startPosition = box2.offsetLeft;   
            var targetPosition = target.getBoundingClientRect().left;  
            }
        var startTime = null;

    function animation(currentTime){
		if(startTime === null) startTime = currentTime;
            var timeElapsed = currentTime - startTime;
            var run = ease(timeElapsed, startPosition, targetPosition, duration);

		scrollContainer.scrollTo(0,run);
        
		if(timeElapsed < duration) requestAnimationFrame(animation);
	}
	
    ease()

	requestAnimationFrame(animation);
};

    function ScrollThree(target, duration){
        var target = document.querySelector(target);
        if(mobileMedia.matches){
            var startPosition = box3.offsetTop;
            var targetPosition = target.getBoundingClientRect().top;    
            }else{
            var startPosition = box3.offsetLeft;   
            var targetPosition = target.getBoundingClientRect().left;  
            }
        var startTime = null;

    function animation(currentTime){
		if(startTime === null) startTime = currentTime;
            var timeElapsed = currentTime - startTime;
            var run = ease(timeElapsed, startPosition, targetPosition, duration);

		scrollContainer.scrollTo(0,run);
        
		if(timeElapsed < duration) requestAnimationFrame(animation);
	}
	
    ease()
	
	requestAnimationFrame(animation);
};

    function ScrollFour(target, duration){
        var target = document.querySelector(target);
        if(mobileMedia.matches){
            var startPosition = box4.offsetTop;
            var targetPosition = target.getBoundingClientRect().top;    
            }else{
            var startPosition = box4.offsetLeft;   
            var targetPosition = target.getBoundingClientRect().left;  
            }
        var startTime = null;

        function animation(currentTime){
		if(startTime === null) startTime = currentTime;
            var timeElapsed = currentTime - startTime;
            var run = ease(timeElapsed, startPosition, targetPosition, duration);

		scrollContainer.scrollTo(0,run);
        
		if(timeElapsed < duration) requestAnimationFrame(animation);
	}	
    ease()
	
	requestAnimationFrame(animation);
};         


export {ScrollOne, ScrollTwo, ScrollThree, ScrollFour};

        let scrollOneToTwo = box1.addEventListener('wheel', (e) => {
            e.preventDefault();
            ScrollOne('#box2', 1300);
        })

        let scrollTwoToThree = box2.addEventListener('wheel', (e) => {    
        if (e.wheelDelta >= 0) {
            e.preventDefault();
            ScrollTwo('#box1', 1300);
        }else {
            e.preventDefault();
            ScrollTwo('#box3', 1300);
        }         
        })

        let scrollThreeToFour = box3.addEventListener('wheel', (e) => {
        if (e.wheelDelta >= 0) {
            e.preventDefault();
            ScrollThree('#box2', 1300);
        }else {
            e.preventDefault();
            ScrollThree('#box4', 1300);
        }         
        })

        let scrollFourToOne = box4.addEventListener('wheel', (e) => {
        if (e.wheelDelta >= 0) {
            e.preventDefault();
            ScrollFour('#box3', 1300);
        }else {
            e.preventDefault();
            ScrollFour('#box1', 1300);
        }              
        })

        export { scrollOneToTwo, scrollTwoToThree, scrollThreeToFour, scrollFourToOne};
        
/*         let touchstartY = 0;
        let touchendY = 0;

        document.addEventListener('touchstart', function(event) {
            touchstartY = event.changedTouches[0].screenY;
        }, false);

        document.addEventListener('touchend', function(event) {
            touchendY = event.changedTouches[0].screenY;
        }, false); 

        box1.addEventListener('touchmove', (e) => {
            e.preventDefault();
            ScrollOne('#box2', 1000);
        })

       box2.addEventListener('touchmove', (e) => {    
        e.preventDefault();
        if (touchendY < touchstartY) {
            ScrollTwo('#box3', 1000);
        } else if (touchendY > touchstartY) {
            ScrollTwo('#box1', 1000);
        }
        })

        box3.addEventListener('touchmove', (e) => {
            e.preventDefault();
            if (touchendY < touchstartY) {
                ScrollThree('#box4', 1000);
            } else  if (touchendY > touchstartY) {
                ScrollThree('#box2', 1000);
            }
        })

        box4.addEventListener('touchmove', (e) => {
            e.preventDefault();
            if (touchendY < touchstartY) {
                ScrollFour('#box1', 1000);
            } else if (touchendY > touchstartY) {
                ScrollFour('#box3', 1000);
            }
        })
 */
        let playNotifIg = document.querySelector('.ig-li');
        let notifIg = document.querySelector('.notif-ig');
        let playNotifLinkedin = document.querySelector('.in-li');
        let notifLinkedin = document.querySelector('.notif-linkedin');
        let playNotifGit = document.querySelector('.git-li');
        let notifGit = document.querySelector('.notif-git');


        playNotifIg.addEventListener('mouseover', () => {
                notifIg.style.display = 'block';
        });

        playNotifIg.addEventListener('mouseleave', () => {
                notifIg.style.display = 'none';
        });
       
        playNotifLinkedin.addEventListener('mouseover', () => {
                notifLinkedin.style.display = 'block';
        });

        playNotifLinkedin.addEventListener('mouseleave', () => {
                notifLinkedin.style.display = 'none';
        });
 
        playNotifGit.addEventListener('mouseover', () => {
                notifGit.style.display = 'block';
        });

        playNotifGit.addEventListener('mouseleave', () => {
                notifGit.style.display = 'none';
        });
           
        
         
